$desktop-width: 1024px;

body.has-backdrop #root {
  filter: blur(8px);
}

@media(min-width: $desktop-width) {
  .hide-desktop {
    display: none !important;
  }
}

@media(max-width: #{$desktop-width - 1px}) {
  .hide-mobile {
    display: none !important;
  }
}