html {
    height: 100%;
    position: relative;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    position: relative;
}

#root {
    height: 100%;
    position: relative;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.stepper-summary-section {
    margin-top: 36px;
}

.stepper-summary-section:first-child {
    margin-top: 0;
}

.stepper-summary-section h4 {
    font-weight: 600;
    margin: 0 0 12px;
}

.stepper-summary-row {
    display: flex;
    margin-bottom: 4px;
    font-size: 14px;
}

.stepper-summary-key-value-row-key {
    text-transform: uppercase;
    color: #999;
}

.stepper-summary-key-value-row-value {
    flex: 1;
    text-align: right;
    color: #009;
}

.stepper-summary-row.alert-danger .stepper-summary-key-value-row-value {
    color: #900;
}

.MuiStepLabel-label {
    text-transform: uppercase;
    font-weight: normal;
}

.MuiStepLabel-label.Mui-active {
    font-weight: normal !important;
}
